<template>
    <Setup-Step
        @yes="f => addNew()"
        @no="next"
        :customizeTo="stage == 0 ? { name: 'product-groups' } : null"
        :loadingMsg="loadingMsg"
        :settings="settings"
        :stage.sync="stage"
        step="product-groups"
        title="Product Groups">
        <template v-slot:body>
            <v-tabs-items v-model="stage" touchless>
                <v-tab-item>
                    <BT-List-Endless
                        :canSearch="false"
                        :loadingMsg.sync="loadingMsg"
                        maxHeight="60vh"
                        navigation="product-groups"
                        :refreshToggle="refreshList">
                        <template v-slot="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.groupName }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn small text @click="addNew(item)">change</v-btn>
                            </v-list-item-action>
                        </template>
                    </BT-List-Endless>

                    <Setup-More :settings="settings">
                        <Setup-Info>
                            Product groups are used to manage restrictions on what customers can access when placing new orders.
                        </Setup-Info>

                        <Setup-Example>
                            <p>Creative Crayons LTD have a basic product group which includes all the normal products that are available to most customers.</p>
                            <p>They also have a special product group which includes the normal products plus some experimental products.  This group is only available to a few select customers.</p>
                        </Setup-Example>
                    </Setup-More>

                    <p class="my-4">Would you like to add a product group?</p>

                </v-tab-item>

                <v-tab-item>
                    <BT-Field-String
                        label="Group Name"
                        v-model="newItem.groupName"
                        isEditing />

                    <BT-Btn 
                        buttonClass="primary mx-1"
                        inline
                        label="Next"
                        @click="save" />
                </v-tab-item>

                <v-tab-item>
                    <p>What products are in this group?</p>
                    
                    <v-expansion-panels v-model="panelV">
                        <BT-Blade-Expansion-Links
                            :itemProperties="['ID','ProductName']"
                            label="Products"
                            linkGetURL="/get/Links?GroupID={id}"
                            linkNavigation="product-groups"
                            manyText="productName"
                            manyProp="productID"
                            manyToMany
                            manyNavigation="products"
                            :resetToggle="resetToggle"
                            :searchProps="['productName']"
                            :singleID="newItem.id"
                            singleProp="productOfferingGroupID"
                            startOpen />

                        <!-- <BT-Blade-Expansion-Links
                            label="Products"
                            manyNavigation="product-ordering"
                            filterProp="CategoryID"
                            :singleID="newItem.id"
                            :itemProperties="['ID','ProductName']"
                            singleProp="categoryID"
                            startEditing
                            startOpen
                            manyText="productName"
                            :searchProps="['productName', 'abbreviation']"
                            small /> -->
                    </v-expansion-panels>
                    
                    <BT-Btn
                        buttonClass="primary mx-1 mt-4"
                        inline
                        label="Done"
                        @click="finish" />
                </v-tab-item>

            </v-tabs-items>
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Product-Group-Setup',
    components: {
        BTBladeExpansionLinks: () => import('~components/BT-Blade-Expansion-Links.vue'),
        SetupExample: () => import('~home/setup/Setup-Example.vue'),
        SetupInfo: () => import('~home/setup/Setup-Info.vue'),
        SetupMore: () => import('~home/setup/Setup-More.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue')
    },
    data() {
        return {
            loadingMsg: null,
            newItem: {},
            panelV: 0,
            refreshList: false,
            resetToggle: false,
            stage: 0,
            isJustNew: false,
        }
    },
    props: {
        settings: {
            type: Object,
            default: null
        },
    },
    methods: {
        addNew(j) {
            this.newItem = j || { };
            this.stage += 1;
        },
        next(nextFunc) {
            nextFunc();
        },
        async save() {
            try {
                this.loadingMsg = 'Saving';
                var res = null;

                if (this.newItem.id == null) {
                    res = await this.$BlitzIt.store.post('product-groups', this.newItem);
                }
                else {
                    res = await this.$BlitzIt.store.patch('product-groups', this.newItem);
                }
                
                this.newItem.id = res.id;
                this.newItem.rowVersion = res.rowVersion;

                this.stage += 1;
                this.resetToggle = !this.resetToggle;
            }
            finally {
                this.loadingMsg = null;
            }
        },
        finish() {
            this.stage = 0;
            this.refreshList = !this.refreshList;
        }
    }
}
</script>